.communities-area {
    display: flex;
    flex-direction: column;
    border-top-right-radius: var(--border-radius-lg);
    border-bottom-right-radius: var(--border-radius-lg);
    background: var(--background-color-3);
    box-shadow: var(--shadow);
}

.communities-area__dropdown,
.communities-area__list,
.communities-area__buttons,
.communities-area__add-community {
    padding: var(--padding);
}

.communities-area__list,
.communities-area__buttons {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid;
    border-image: radial-gradient(var(--background-color-5) 80%, transparent 80%);
    border-image-slice: 1;
}

.communities-area__list {
    gap: var(--gap);
    height: 100%;
    overflow-y: auto;
    border-top: 2px solid;

    &::-webkit-scrollbar {
        display: none;
    }
}

.communities-area__buttons {
    align-items: center;

    &:empty {
        display: none;
    }
}

.communities-area__community {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    aspect-ratio: 1;
    border-radius: 50%;
    border: none;
    background-size: 4px 4px;
    color: black;
    font-family: var(--primary-font);
    font-weight: bolder;
    font-size: 1rem;
    cursor: pointer;
    transition: border-radius var(--transition-duration);
}

.communities-area__community:hover {
    border-radius: var(--border-radius-lg);
}

.communities-area__add-community-button {
    --primary-background: var(--primary-color);
    --secondary-background: var(--secondary-color);
    display: grid;
    place-items: center;
    width: 100%;
    aspect-ratio: 1;
    border-radius: var(--border-radius);
    border: none;
    background: linear-gradient(135deg, var(--primary-background), var(--secondary-background));
    cursor: pointer;
    transition-property: border-radius, box-shadow;
    transition-duration: var(--transition-duration);

    &:hover {
        border-radius: 50%;
        box-shadow: 0 2px 10px 5px var(--shadow-color);
        animation: communities-area__ripple 2s linear infinite,
            communities-area__float 2s linear infinite;
    }

    &>svg {
        width: 32px;
        height: 32px;
        fill: var(--background-color-1);
        mix-blend-mode: plus-darker;
    }
}

@keyframes communities-area__float {
    40% {
        translate: 0 -2px;
    }

    90% {
        translate: 0 2px;
    }
}

@keyframes communities-area__ripple {
    40% {
        --primary-background: var(--secondary-color);
    }

    50% {
        filter: saturate(300%);
    }

    60% {
        --secondary-background: var(--primary-color);
    }
}

@property --primary-background {
    syntax: "<color>";
    inherits: false;
    initial-value: white;
}

@property --secondary-background {
    syntax: "<color>";
    inherits: false;
    initial-value: white;
}